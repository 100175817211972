import { Slide } from "@material-ui/core";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "../notifier/notifierSlice";
import { api_post_serialize, api_post, api_patch_serialize } from "utils/Api";

import axios from "./../../../utils/axios";

// urls:
const url = "/materiels";
const historyUrl = "/user-materiels";
const materialUrl = "materiels";
// fetch all materials from server
export const getAllMateriels = createAsyncThunk(
  "materiels/getAll",
  async ({ page, options, rowsPerPage }, thunkAPI) => {
    try {
      const fetchedMaterials = await axios.get(
        url + `?page=${page}${options}&limit=${rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (fetchedMaterials.status === 200) {
        const { data } = fetchedMaterials;
        return data;
      }
      throw new Error(fetchedMaterials.statusText);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMaterialHistoryOfUsers = createAsyncThunk(
  " Material-History/OfUser",
  async (materialId, thunkAPI) => {
    try {
      const materialHistory = await axios.get(
        `${historyUrl}/?history=true&material=${materialId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (materialHistory.status === 200) {
        return materialHistory.data;
      }
      throw new Error(materialHistory.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//get my requests 
export const getUsersRequests = createAsyncThunk(
  " Material-History/getUsersRequests",
  async (body, thunkAPI) => {
    try {
      const userRequests = await axios.get(
        `/requests/myRequests?page=${body.page}&limit=${body.rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }, 
        }
      );
      if (userRequests.status === 200) {
        return userRequests.data;
      }
      throw new Error(userRequests.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getUsersReclamations = createAsyncThunk(
  " Material-History/getUsersReclamations",
  async (body, thunkAPI) => {
    try {
      const userRequests = await axios.get(
        `/requests/myReclamations?page=${body.page}&limit=${body.rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }, 
        }
      );
      if (userRequests.status === 200) {
        return userRequests.data;
      }
      throw new Error(userRequests.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// fetch one material

export const getOneMaterial = createAsyncThunk(
  "material/getOne",
  async (materialId, thunkAPI) => {
    try {
      const fetchedSigneMaterial = await axios.get(`${url}/${materialId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (fetchedSigneMaterial.status === 200) {
        return fetchedSigneMaterial.data.data;
      }
      throw new Error(fetchedSigneMaterial.status);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.rejectWithValue(message);
      console.log(error?.message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// create material
export const createMaterial = createAsyncThunk(
  "post/create",
  async (materialToCreate, thunkAPI) => {
    try {
      let array = [0, 0];
      let letters = [...Array(26)].map((_, i) => String.fromCharCode(i + 97));
      materialToCreate.materiels.forEach((material) => {
        if (material.serial_number === null || !material.serial_number) {
          let randomized;
          array.forEach((el, index) => {
            array[index] = String(
              Math.floor(Math.random() * 10000) +
                letters[Math.trunc(Math.random() * letters.length)] +
                letters[Math.trunc(Math.random() * letters.length)] +
                String(Math.floor(Math.random() * 10000))
            );
            randomized = array[0];
          });
          material.serial_number = material.name.slice(0, 2) + "-" + randomized;
        }
      });
      const createdMaterial = await api_post_serialize(
        materialUrl,
        materialToCreate,
        true
      );
      // const data = createdMaterial.data
      if (createdMaterial.status === "success") {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              createdMaterial?.data?.message ||
              "Material is successfully created",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = "/materials";
        }, 500);
      }
      if (createdMaterial.status === "fail") {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              createdMaterial?.data?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message:
            err.message || "Something went wrong, Could not create material",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : "Something went wrong");
    }
  }
);
// update material
export const editMaterial = createAsyncThunk(
  "material/edit",
  async (updateMaterialData, thunkAPI) => {
    try {
      const id = String(updateMaterialData?.id);
      delete updateMaterialData.id;
      if (!updateMaterialData.image) {
        delete updateMaterialData.image;
      }
      if (!updateMaterialData.note) {
        delete updateMaterialData.note;
      }

      let array = [0, 0];
      let letters = [...Array(26)].map((_, i) => String.fromCharCode(i + 97)); 
      if (updateMaterialData.serial_number === null) {
        let randomized;
        array.forEach((el, index) => {
          array[index] = String(
            Math.floor(Math.random() * 10000) +
              letters[Math.trunc(Math.random() * letters.length)] +
              letters[Math.trunc(Math.random() * letters.length)] +
              String(Math.floor(Math.random() * 10000))
          );
          randomized = array[0];
        });
        updateMaterialData.serial_number =
          updateMaterialData.name.slice(0, 2) + "-" + randomized;
      }
      const updatedMaterial = await api_patch_serialize(
        `${url}`,
        id,
        updateMaterialData,
        true
      );
      if (updatedMaterial.status === "success") {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              updatedMaterial?.data?.message ||
              "Material is successfully updated",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = "/materials";
        }, 500);
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message:
            err.message || "Something went wrong, Could not update material",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log(err);
      return Promise.reject(err.message ? err.message : "Something went wrong");
    }
  }
);

// delete material
export const deleteMaterial = createAsyncThunk(
  "material/delete",
  async (materialId, thunkAPI) => {
    try {
      const deletedMaterial = await axios.delete(`${url}/${materialId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (deletedMaterial.status === 200) {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              deletedMaterial?.data?.message ||
              "Material is successfully removed",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        return deletedMaterial.data.data;
      }
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message:
            err.message || "Something went wrong, Could not remove material",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log('err',err);
      return Promise.reject(err.message ? err.message : "Something went wrong");
    }
  }
);
export const importFileMaterials = createAsyncThunk(
  "user/importFileMaterials",
  async (query, thunkAPI) => {
    const { requestValues } = query;
    let data;
    try {
      const importedMaterials = await api_post(
        `materiels/import`,
        requestValues,
        true
      );
      data = await importedMaterials.data;
      if (importedMaterials.status === "success") {
        thunkAPI.dispatch(
          enqueueSnackbar({
            message:
              importedMaterials?.data?.message ||
              "Materials data imported successfully",
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              TransitionComponent: Slide,
            },
          })
        );
        setTimeout(() => {
          window.location.pathname = "/materials";
        }, 500);
        return importedMaterials.data.data;
      }
      throw new Error(importedMaterials.message);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: err?.message || "Something went wrong",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
      console.log("error", err?.message);
      return Promise.reject(err.message ? err.message : err?.message);
    }
  }
);

const initialBrowserState = {
  result: null,
  status: "idle",
  materials: [],
  userRequests:[],
  singleMaterial: {
    data: [],
    status: "idle",
    error: null,
  },
  materialsDataHistory: [],
  meta: null,
  occasionalMessage: null,
  importedData:  {
  data:[],
  status:'idle',
  error: null,
  },
  createdMaterials:  {
    data:[],
    status:'idle',
    error: null,
    }
};

// slice
const materialsSlice = createSlice({
  name: "materialSlice",
  initialState: initialBrowserState,
  reducers: {},
  extraReducers: (builder) => {
    //reducers
    builder
      // get materials request
      .addCase(getAllMateriels.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAllMateriels.fulfilled, (state, action) => {
        state.status = "succeeded"
        if(action.payload?.data.length >= 0)
        state.materials = action.payload;
      })
      .addCase(getAllMateriels.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(getMaterialHistoryOfUsers.pending, (state, action) => {})
      .addCase(getMaterialHistoryOfUsers.fulfilled, (state, action) => {
        state.materialsDataHistory = action.payload?.data;
      })
      .addCase(getMaterialHistoryOfUsers.rejected, (state, action) => { })
      
 // get a myRequests
      .addCase(getUsersRequests.pending, (state, action) => {})
      .addCase(getUsersRequests.fulfilled, (state, action) => {
        
        state.userRequests = action.payload;
      })
      .addCase(getUsersReclamations.rejected, (state, action) => {})
       // get a myReclamations
       .addCase(getUsersReclamations.pending, (state, action) => {})
       .addCase(getUsersReclamations.fulfilled, (state, action) => {
         
         state.userReclamations = action.payload;
       })
       .addCase(getUsersRequests.rejected, (state, action) => {})
      // get a material
      .addCase(getOneMaterial.pending, (state, action) => {
        state.singleMaterial.status = "loading";
      })
      .addCase(getOneMaterial.fulfilled, (state, action) => {
        state.singleMaterial.status = "succeeded";
        state.singleMaterial.data = action.payload;
      })
      .addCase(getOneMaterial.rejected, (state, action) => {
        state.singleMaterial.status = "failed";
        state.singleMaterial.error = action.payload;
      })
      // create material request
      .addCase(createMaterial.pending, (state, action) => {
        state.createdMaterials.status = "loading";
      })
      .addCase(createMaterial.fulfilled, (state, action) => {
        state.createdMaterials.status = "succeeded";
      })
      .addCase(createMaterial.rejected, (state, action) => {
        state.createdMaterials.status = "failed";
      })
      // edit material request
      .addCase(editMaterial.pending, (state, action) => {})
      .addCase(editMaterial.fulfilled, (state, action) => {})
      .addCase(editMaterial.rejected, (state, action) => {
        state.occasionalMessage = action.payload;
      })
      // remove material request
      .addCase(deleteMaterial.pending, (state, action) => {})
      .addCase(deleteMaterial.fulfilled, (state, action) => {})
      .addCase(deleteMaterial.rejected, (state, action) => {
        state.occasionalMessage = action.payload;
      })
      .addCase(importFileMaterials.pending, (state, action) => {
        state.importedData.status = "loading"
      })
      .addCase(importFileMaterials.fulfilled, (state, action) => {
        state.importedData.status = "succeeded"
        state.importedData = action.payload.data;
      })
      .addCase(importFileMaterials.rejected, (state, action) => {
        state.importedData.status = "failed"
      });
  },
});

export default materialsSlice.reducer;
