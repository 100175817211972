// @flow
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import GetAppIcon from "@material-ui/icons/GetApp";
import { AsyncPaginate } from "react-select-async-paginate";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Box,
  CardMedia,
  useMediaQuery,
  Slide,
  Tooltip,
} from "@material-ui/core";
import dayjs from "dayjs";
import { api_post } from "../../../utils/Api";
import clsx from "clsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import dayjsAdapter from "@material-ui/pickers/adapter/dayjs";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import SnackBar from "../../../components/SnackBar";
import MenuItem from "@material-ui/core/MenuItem";
import LoadingApi from "components/LoadingApi/LoadingApi";
import theme from "theme";
import { useDispatch, useSelector } from "react-redux";
import {
  createMaterial,
  importFileMaterials,
} from "redux/slices/materials/materialsSlice";
import { enqueueSnackbar } from "redux/slices/notifier/notifierSlice";
import { getLocations, getModeles, getTypes, getStatus, getBrand } from "utils/methods";
import  Download from '../../../assets/images/Download.svg';
import  deleteIcon from '../../../assets/images/deleteIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    visibility: "hidden",
    height: "0px",
    padding: "0",
    margin: "0",
  },
  importButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-2),
  },
  downloadButton: {
    marginRight: theme.spacing(1),
  },
  CopyButton: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),

  },
  addButton: {
    marginBottom: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));
type Props = {
  edit: boolean,
  className: string,
};

const initialState = {
  materials: [
    {
      name: "",
      note: null,
      status_id: 0,
      brand_id: "",
      type_id: null,
     // available: "1",
      serial_number: null,
      image: [],
      location_id: null,
      model_id: "",
      price: null,
      date_of_purchase: dayjs().format("YYYY-MM-DD"),
    },
  ],
};

const Form = (props: Props): React$Element<any> => {
  const { id } = useParams();
  const { className, edit, ...rest } = props;
  const dispatch = useDispatch();
  const [message, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [severity, setAlertSeverity] = useState("success");
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState([]);
  const [values, setValues] = useState(initialState);
  const [numberOfCopies, setNumberOfCopies] = useState("1");
  const [indexCounter, setIndexCounter] = useState("1");
  const [fileDeleted, setFileDeleted] = useState(false);
  const { singleMaterial } = useSelector((state) => state.materials);
  const { importedData } = useSelector((state) => state.materials);
  const { statusData } = useSelector((state) => state.status);
  const { brandData } = useSelector((state) => state.brand);
  const { typeData } = useSelector((state) => state.type);
  const { modeleData } = useSelector((state) => state.modele);
  const { locationData } = useSelector((state) => state.location);
  const [brandInput, setBrandInput] = useState('');
  const [typeInput, setTypeInput] = useState('');
  const [resetShow, setResetShow] = useState(false);
  const [locationInput, setLocationInput] = useState('');
  const [modeleInput, setModeleInput] = useState('');
  const [statusInput, setStatusInput] = useState('');


const { createdMaterials } = useSelector((state) => state.materials)
  useEffect(() => {
    if(!edit){
      setValues(initialState);
    }
  }, []);

  const serialNumberGeneration = () => {
    dispatch(
      enqueueSnackbar({
        message:
          "If the Material has no Serial Number, keep the field empty to auto-generate",
        options: {
          key: new Date().getTime() + Math.floor(Math.random() * 150),
          variant: "info",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          TransitionComponent: Slide,
        },
      })
    );
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseLoading = () => {
    setLoading(false);
  };

  const onFileUploadChange = (e) => {
    const file = e.target.files[0];
    onFileUpload(file);
  };

  const onFileUpload = (file) => {
    dispatch(importFileMaterials({ requestValues: { materials: file } }));
  };

  const formValidationSerial = (values) => { 
    const uniqueValues = new Set(values?.materials.map(v => v.serial_number));
    if (Object.values(uniqueValues).includes(undefined) && uniqueValues.size < values?.materials.length) {
      return { success: false, message: "Duplicated Serial Number found "};
    }
  }
  const formValidation = (values) => {
    return values?.materials.map((material, index) => {
      let arrayOfErrors = [];
      if (!material?.name) {
        arrayOfErrors.push({
          success: false,
          message: "Name " + [index + 1] + " is missing",
        });
        //   return { success: false, message: "Name " + [index + 1] + " is missing" };
      }
      if (!material?.status_id) {
        arrayOfErrors.push({
          success: false,
          message: "Status " + [index + 1] + " is missing",
        });
        //   return { success: false, message: "Status " + [index + 1] + "  is missing" };
      }
      if (!material?.brand_id) {
        arrayOfErrors.push({
          success: false,
          message: "Brand" + [index + 1] + " is missing",
        });
        //   return { success: false, message: "Type " + [index + 1] + "  is missing" };
      }
      if (!material?.location_id) {
        arrayOfErrors.push({
          success: false,
          message: "Location" + [index + 1] + " is missing",
        });
        //   return { success: false, message: "Type " + [index + 1] + "  is missing" };
      }
      // if (!material?.price) {
      //   arrayOfErrors.push({
      //     success: false,
      //     message: "Price" + [index + 1] + " is missing",
      //   });
      //   //   return { success: false, message: "Type " + [index + 1] + "  is missing" };
      // }
      if (!material?.model_id) {
        arrayOfErrors.push({
          success: false,
          message: "Modele" + [index + 1] + " is missing",
        });
        //   return { success: false, message: "Type " + [index + 1] + "  is missing" };
      }
      if (!material?.type_id) {
        arrayOfErrors.push({
          success: false,
          message: "Type " + [index + 1] + " is missing",
        });
        //   return { success: false, message: "Type " + [index + 1] + "  is missing" };
      }
      return arrayOfErrors;
    });
  };

  const onFileChange = (event, index) => {
    let newArr = { ...values };
    if (event.target.files[0]) {
      for (let i = 0; i < event.target.files.length ; i++) {
        newArr.materials[index]["image"].push(event.target.files[i]);
        if(newArr.materials[index]["image"].length !==0){
          setResetShow(true);
          }
        setValues({ ...newArr });
      }

    }
   
  };

  const handleReset = (event,index) => {
    const newArr = { ...values };
    newArr.materials[index]["image"] = [];
    setValues({ ...newArr });
 //   setResetShow(false);
  };

  const hiddenFileInput = React.useRef(null);
  const hiddenResetInput = React.useRef(null);

  const handleClickReset = () => {
    hiddenResetInput.current.click();
  };

  const handleClickUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleSubmit = () => {
    const validations = formValidation(values).filter(
      (validation) => validation.length
    );
    const validationsSerial = formValidationSerial(values);
    if (validations.length > 0) {
      validations.forEach((validation) => {
        validation.forEach((validationObj) => {
          dispatch(
            enqueueSnackbar({
              message: validationObj?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                TransitionComponent: Slide,
              },
            })
          );
        });
      });
    } 
    if (validationsSerial) {
      dispatch(
        enqueueSnackbar({
          message: validationsSerial.message,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    }
    else {
      const requestValues = values.materials;
      requestValues.map((item) => {
        item.available = true;
        item.location_id = item.location_id?._id;
        item.brand_id = item.brand_id?._id;
        item.type_id = item.type_id?._id;
        item.status_id = item.status_id?._id;
        item.model_id = item.model_id?._id;
        if (item?.note === "" || item?.note === null) {
          delete item.note;
        }
        if (item?.price === "" || item?.price === null) {
          delete item.price;
        }
      });
      dispatch(createMaterial({ materiels: requestValues }));
    }
  };
  const AddRemoveMaterial = (operation, index) => {
    if (operation == "plus") {
      setValues((previousData) => ({
        ...previousData,
        materials: values?.materials?.concat({
          name: "",
          note: null,
          status_id: 0,
          brand_id: "",
          type_id: null,
          available: "1",
          serial_number: null,
          image: [],
        }),
      }));
    } else {
      setValues((previousData) => ({
        ...previousData,
        materials: values.materials.filter((num, i) => {
          return i != index;
        }),
      }));
    }
  };

  const thisArray = [];
  const duplicataMaterial = () => {
    for (let i = 0; i <= numberOfCopies - 1 ; i++) {
      const lengthArr = (values.materials).length - 1;
      thisArray.push({
        name: values?.materials[lengthArr]?.name,
        note: values?.materials[lengthArr]?.note || null,
        price: values?.materials[lengthArr]?.price,
        date_of_purchase: values?.materials[lengthArr]?.date_of_purchase,
        status_id: values?.materials[lengthArr]?.status_id,
        brand_id: values?.materials[lengthArr]?.brand_id,
        type_id: values?.materials[lengthArr]?.type_id,
        model_id: values?.materials[lengthArr]?.model_id,
        location_id: values?.materials[lengthArr]?.location_id,
        available: values?.materials[lengthArr]?.available,
        image: values?.materials[lengthArr]?.image,
      });
    }
    setNumberOfCopies(1);
    setValues((prev) => ({
      ...prev,
      materials: values.materials.concat(thisArray),
    }));
  };   

  const handleNumberOfCopies = (event) => {
    setNumberOfCopies(event.target.value);
  };

  const handleFiledsChange = (event, index) => {
    const newArr = { ...values };
    newArr.materials[index][event.target.name] = event.target.value;
    setValues({ ...newArr });
  };
  const handleLocationChange = (value,index) => {
    const newArr = { ...values };
    newArr.materials[index]["location_id"] = value;
    setValues({ ...newArr });
  };

  const handleModeleChange = (value,index) => {
    const newArr = { ...values };
    newArr.materials[index]["model_id"] = value;
    setValues({ ...newArr });
  };

  const handleBrandChange = (value,index) => {
    const newArr = { ...values };
    newArr.materials[index]["brand_id"] = value;
    setValues({ ...newArr });
  };

  const handleStatusChange = (value,index) => {
    const newArr = { ...values };
    newArr.materials[index]["status_id"] = value;
    setValues({ ...newArr });
  };

  const handleTypeChange = (value,index) => {
    const newArr = { ...values };
    newArr.materials[index]["type_id"] = value;
    setValues({ ...newArr });
  };

  const datePurshaseChange = (value, index) => {
    if (value) {
      const newArr = { ...values };
      newArr.materials[index]["date_of_purchase"] = value.format(
        "YYYY-MM-DD hh:mm"
      );
      setValues({ ...newArr });
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: ".2rem",
        }}
        className={classes.row}
      >
        <span className={classes.spacer} />
        <CardMedia>
          <Button
            component="label"
            color="primary"
            variant="outlined"
            fullWidth
            startIcon={<CloudUploadIcon />}
            className={classes.importButton}
          >
            Import
            <input
              type="file"
              style={{ display: "none" }}
              onChange={onFileUploadChange}
            />
            {importedData.status === 'loading' && <CircularProgress size={20} style={{ margin: 'auto auto' }} />}
          </Button>
        </CardMedia>
        <CardMedia>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            startIcon={<GetAppIcon />}
            component={"a"}
            href={"/samples/sampleMaterial.xlsx"}
            className={classes.downloadButton}
          >
            Download Sample
          </Button>
        </CardMedia>
      </div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off" noValidate>
          <CardHeader subheader="Materials form" />
          <Divider />
          <CardContent>
            {values?.materials?.map((material, index) => {
              return (
                <div>
                 <p style={{fontSize:'1.5rem',color:'#FD9D59', marginTop:'1rem'}}>{index + 1}</p>
                  <Grid container spacing={3} key={index}>
                    <Grid item md={2} xs={4}>
                      <TextField
                        required
                        fullWidth
                        name="name"
                        id="name"
                        variant="outlined"
                        label="Name"
                        value={material.name ? material.name : material["name"]}
                        onChange={(e) => handleFiledsChange(e, index)}
                      ></TextField>
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <TextField
                        fullWidth
                        name="note"
                        variant="outlined"
                        label="Note"
                        value={material.note ? material.note : material["note"]}
                        onChange={(e) => handleFiledsChange(e, index)}
                      ></TextField>
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <TextField
                        onFocus={serialNumberGeneration}
                        required={true}
                        className="inp"
                        fullWidth
                        name="serial_number"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label={"Serial Number"}
                        value={
                          material.serial_number
                            ? material.serial_number
                            : material["serial_number"]
                        }
                        onChange={(e) => handleFiledsChange(e, index)}
                      ></TextField>
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <TextField
                        fullWidth
                        name="price"
                        id="price"
                        variant="outlined"
                        type="number"
                        label="Price"
                        min={0}
                        value={
                          material.price ? material.price : material["price"]
                        }
                        onChange={(e) => handleFiledsChange(e, index)}
                      ></TextField>
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <LocalizationProvider dateAdapter={dayjsAdapter}>
                        <DatePicker
                          label="Date of Purshase"
                          name="date_of_purchase"
                          inputVariant="outlined"
                          inputFormat="DD/MM/YYYY"
                          value={
                            material.date_of_purchase ||
                            material["date_of_purchase"]
                          }
                          onChange={(e) => datePurshaseChange(e, index)}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item md={2} xs={4}>
                    <AsyncPaginate
                          required
                          name={"status_id"}
                          loadOptions={getStatus}
                          value={ material.status_id ||
                            material["status_id"]}
                          onChange={(value) => handleStatusChange(value, index)}
                          getOptionLabel={(option) =>
                            option?.name
                          }
                          getOptionValue={(option) => option._id}
                          inputValue={statusInput}
                          onInputChange={setStatusInput}
                          placeholder='Status *'
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                        />
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <AsyncPaginate
                          required
                          name={"brand_id"}
                          loadOptions={getBrand}
                          value={ material.brand_id ||
                            material["brand_id"]}
                          onChange={(value) => handleBrandChange(value, index)}
                          getOptionLabel={(option) =>
                            option?.name
                          }
                          getOptionValue={(option) => option._id}
                          inputValue={brandInput}
                          onInputChange={setBrandInput}
                          placeholder='Brand *'
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                        />
                    </Grid>
                    <Grid item md={2} xs={4}>
                    <AsyncPaginate
                          required
                          name={"type_id"}
                          loadOptions={getTypes}
                          value={ material.type_id ||
                            material["type_id"]}
                          onChange={(value) => handleTypeChange(value, index)}
                          getOptionLabel={(option) =>
                            option?.name
                          }
                          getOptionValue={(option) => option._id}
                          inputValue={typeInput}
                          onInputChange={setTypeInput}
                          placeholder='Type *'
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                        />
                    </Grid>
                    <Grid item md={2} xs={4}>
                    <AsyncPaginate
                          required
                          name={"model_id"}
                          loadOptions={getModeles}
                          value={ material.model_id ||
                            material["model_id"]}
                          onChange={(value) => handleModeleChange(value, index)}
                          getOptionLabel={(option) =>
                            option?.name
                          }
                          getOptionValue={(option) => option._id}
                          inputValue={modeleInput}
                          onInputChange={setModeleInput}
                          placeholder='Modele *'
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                        />
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <AsyncPaginate
                          required
                          name={"location_id"}
                          loadOptions={getLocations}
                          value={ material.location_id ||
                            material["location_id"]}
                          onChange={(value) => handleLocationChange(value, index)}
                          getOptionLabel={(option) =>
                            option?.name
                          }
                          getOptionValue={(option) => option._id}
                          inputValue={locationInput}
                          onInputChange={setLocationInput}
                          placeholder='Locations *'
                          additional={{
                            page: 1,
                          }}
                          styles={{
                            control: (base) => ({
                              ...base,
                              minHeight: '53px',
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: '#000',
                              fontSize: theme.typography.fontSize,
                              fontFamily: theme.typography.fontFamily,
                            }),
                          }}
                          menuPortalTarget={document.querySelector('body')}
                        />
                    </Grid>
                    {/* <Grid style={{ position: "relative" }} item md={2} xs={4}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Available</FormLabel>
                        <RadioGroup
                          aria-label="Available"
                          name="available"
                          onChange={(e) => handleFiledsChange(e, index)}
                          value={
                            material.available
                              ? material.available
                              : material["available"]
                          }
                          row
                        >
                          <FormControlLabel
                            control={<Radio />}
                            value="1"
                            label="In Stock"
                          />
                          <FormControlLabel
                            control={<Radio />}
                            value="0"
                            label="In use"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid> */}
                    <Grid
                          item
                          md={2}
                          xs={2}>
                         <input 
                            type='file' 
                            style={{display:'none'}} 
                            id={"input-file"}
                            ref={hiddenFileInput} 
                            accept=".jpeg, .png, .jpg" 
                            multiple 
                            onChange={(e) => onFileChange(e, index)} />
                            <Tooltip
                               enterDelay={300}
                               title="Upload image(s)">
                          <img 
                            style={{cursor:'pointer'}} 
                            alt="upload img" src={Download} 
                            onClick={handleClickUpload}/>
                            
                            </Tooltip>
                          <input 
                            type="reset" 
                            style={{display:'none'}} 
                            id={"input-file"}
                            ref={hiddenResetInput} 
                            onClick={handleClickReset}/>
                          {resetShow &&
                          <img 
                             style={{ marginLeft:'2px', cursor:'pointer'}} 
                             alt="delete img" 
                             src={deleteIcon} 
                             onClick={(e) => handleReset(e,index)}/>}

                                {material.image.map((item)=>
                                <li>{item.name}</li>)}
                 </Grid>
                    {/* <Grid item md={4} xs={12}></Grid> */}
                    <Grid item m={1}>
                      <Box m={0} pt={1} hidden={index == 0}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={(event) => AddRemoveMaterial(event, index)}
                        >
                          -
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </CardContent>
          <Divider />
          <CardActions
            style={{ flexDirection: "row-reverse", alignItems: "center" }}
          >
             <Grid container spacing={3}>
              <Grid item md={2} xs={4} className={classes.content}>
                <TextField>name</TextField>
              </Grid>
              <Grid item md={2} xs={4} className={classes.content}>
                <TextField></TextField>
              </Grid>
              <Grid item md={2} xs={4} className={classes.content}>
                <TextField></TextField>
              </Grid>
              <Grid item md={2} xs={4} className={classes.content}>
                <TextField>
                  {statusData?.data?.map((status) => {
                    return <MenuItem></MenuItem>;
                  })}
                </TextField>
              </Grid>
              <Grid item md={2} xs={4} className={classes.content}>
                <TextField>
                  {brandData?.data?.map((brand) => {
                    return (
                      <MenuItem key={brand?._id} value={brand?._id}></MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item md={2} xs={4} className={classes.content}>
                <TextField>
                  {typeData?.data?.map((type) => {
                    return (
                      <MenuItem key={type?._id} value={type?._id}>
                        {type.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid
                container
                spacing={1}
                style={{ marginLeft: "10px", marginBottom: "10px" }}
               >
                <Grid>
                  <Box m={1}>
                    <Button
                      className={classes.addButton}
                      color="primary"
                      variant="contained"
                      onClick={() => AddRemoveMaterial("plus")}
                    >
                      +
                    </Button>
                  </Box>
                </Grid>
                <Grid>
                  <Box item md={2} xs={4}>
                    <Button
                      className={classes.CopyButton}
                      color="primary"
                      variant="contained"
                      onClick={() => duplicataMaterial()}
                    >
                      Copy
                    </Button>
                  </Box>
                </Grid>
                <Grid>
                  <TextField
                    fullWidth
                    label="Number of copies"
                    name="copies"
                    onChange={handleNumberOfCopies}
                    type="number"
                    InputProps={{ inputProps: { min: 1} }}
                    required
                    value={numberOfCopies || ""}
                    variant="outlined"
                  />
                </Grid>
                
              </Grid>
              </Grid>
          </CardActions>
              <Divider />
                   <Grid item md={2} xs={4}>
                    <Box mt={1}>
                    <Button
                      disabled={createdMaterials.status === 'loading' ? true : false }
                      style={{ width: "150px", marginLeft: "10px", marginBottom: "10px" }}
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}
                      >
                      Save details
                      {createdMaterials.status === 'loading' && <CircularProgress size={20} style={{ margin: 'auto', color: 'white'}} />}
                    </Button>
                  </Box>
                </Grid>

        </form>
      </Card>

      <SnackBar
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
      <LoadingApi
        open={singleMaterial.status === "loading"}
        onClick={handleCloseLoading}
      />
    </div>
  );
};

Form.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
  handleCloseLoading: PropTypes.func,
  loading: PropTypes.bool,
};

export default Form;